//
// authentication.scss
//


// authentication home icon
.home-btn {
    position: absolute;
    top: 15px;
    right: 25px;
}

// auth 2

.auth-logo {
    .auth-logo-dark {
        display: $display-block;
    }

    .auth-logo-light {
        display: $display-none;
    }
}

.auth-body-bg {
    //background-color: $card-bg;
}


// auth-pass-inputgroup

.auth-pass-inputgroup {
    input[type="input"] + .btn .mdi-eye-outline {
        &:before {
            content: "\F06D1";
        }
    }
}

// authentication full page

.auth-full-bg {
    background-color: rgba($primary, 1);

    //display: flex;
    //
    @media (min-width: 1200px) {
        height: 100vh;
        width: 100%
    }
    //
    //
    //&::before {
    //    content: "";
    //    position: absolute;
    //    width: 300px;
    //    height: 300px;
    //    border-radius: 50%;
    //}

    .bg-overlay-hi-lite-30 {
        background: url("../../../images/auth/bg-overlay-hi-lite-30.png");
        background-size: cover;
        //background-repeat: no-repeat;
        //background-position: center;
        height: 100vh;
        //width: 100%;
    }

    .bg-overlay-hi-lite-30-duo {
        background-size: cover;
        background: black url("../../../images/auth/hi-lite-30-duo-2-1.png") no-repeat center;
        height: 100vh;
        min-height: 100%;
        //width: 100%;
    }

    .bg-overlay-control {
        background-size: cover;
        background: #ffffff url("../../../images/putting-you-in-control.svg") no-repeat center;
        height: 100vh;
        min-height: 100%;
        //width: calc(100% - 30px);
        width: calc(70% - 30px);
        margin-left: auto;
        margin-right: auto;
    }

    .bg-overlay-hi-lite-classic-hero {
        background: url("../../../images/auth/Hi-Lite-Classic-hero.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 100vh;
        width: 100%;
    }
}

//.auth-full-page-content {
//    display: flex;
//
//    @media (min-width: 1200px) {
//        min-height: 100vh;
//    }
//}
/*
.owl-stage {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-box;
    display: box;
}
.owl-item {
    display: inline !important;
}
.auth-review-carousel {
    &.owl-theme {
        .owl-stage-outer {
            height: 100%;
        }



        .owl-carousel {
            height: 100%;
        }

        .owl-dots {
            .owl-dot {
                span {
                    background-color: rgba($gray-100, 0.25);
                }

                &.active, &:hover {
                    span {
                        background-color: $gray-100;
                    }
                }
            }
        }
    }
}
*/
