//
// Datepicker
//

[dir="rtl"] {
    .datepicker {
        right: 0 !important;
        left: auto;
    }
}

[dir="rtl"] {
    .bootstrap-timepicker-widget {
        right: 0 !important;
        left: auto;

    }

    .timepicker-orient-top {
        top: $input-height !important;
    }

    .timepicker-orient-bottom {
        top: auto !important;
        bottom: $input-height !important;
    }
}

.datepicker {
    border: 1px solid $border-color;
    padding: 8px;
    z-index: 999 !important;


    table {
        tr {
            th {
                font-weight: 500;
            }

            td {
                &.active, &.active:hover, .active.disabled, &.active.disabled:hover,
                &.today, &.today:hover, &.today.disabled, &.today.disabled:hover,
                &.selected, &.selected:hover, &.selected.disabled, &.selected.disabled:hover,
                span.active.active, span.active:hover.active {
                    background-color: $primary !important;
                    background-image: none;
                    box-shadow: none;
                    color: $white !important;
                }

                &.day.focused,
                &.day:hover,
                span.focused,
                span:hover {
                    background: $light;
                }

                &.new,
                &.old,
                span.new,
                span.old {
                    color: $gray-500;
                    opacity: 0.6;
                }

                &.range, &.range.disabled, &.range.disabled:hover, &.range:hover {
                    background-color: $gray-300;
                }
            }
        }
    }
}

.table-condensed {
    > thead > tr > th, > tbody > tr > td {
        padding: 7px;
    }
}

.bootstrap-datepicker-inline {
    .datepicker-inline {
        width: auto !important;
        display: inline-block;
    }
}


// DATEPICKER

.datepicker-container {
    border: 1px solid $border-color;
    box-shadow: none;
    background-color: $dropdown-bg;

    &.datepicker-inline {
        width: 212px;
    }
}

.datepicker-panel {

    > ul {
        > li {
            background-color: $dropdown-bg;
            border-radius: 4px;

            &.picked, &.picked:hover {
                background-color: rgba($primary, 0.25);
                color: $primary;
            }

            &.highlighted, &.highlighted:hover, &:hover {
                background-color: $primary;
                color: $white;
            }


            &.muted, &.muted:hover {
                color: $gray-500;
                opacity: 0.6;
            }
        }

        &[data-view=week] {
            > li {
                font-weight: $fw-medium;
            }

            > li, > li:hover {
                background-color: $dropdown-bg;
            }
        }
    }
}

ngb-datepicker {
    background-color: $dropdown-bg;
    position: absolute;
    z-index: $zindex-dropdown;
}

ngb-datepicker-navigation-select > .custom-select {
    margin: 0 5px;
}

.ngb-dp-day,
.ngb-dp-today {
    outline: none !important;

    .btn-light,
    .btn-primary {
        box-shadow: none !important;
        outline: none !important;
        border-radius: 50%;
    }
}

.ngb-dp-weekday {
    font-style: normal !important;
}

.ngb-tp {
    box-shadow: $box-shadow;
    background-color: $dropdown-bg;
    margin-top: 5px;
    float: left;
    min-width: 10rem;
    padding: 0.75rem;
    margin: 0.125rem 0 0;
    font-size: 0.875rem;
    color: $dropdown-color;
    text-align: left;
    list-style: none;
    background-clip: padding-box;
    border: $dropdown-border-width solid $dropdown-border-color;
    border-radius: $dropdown-border-radius;
    position: absolute;
    z-index: $zindex-dropdown;
}

// Date Range
.custom-day {
    line-height: 2rem;
    border-radius: 50%;
    padding: 0 !important;
    outline: none !important;

    &:hover {
        background-color: $primary !important;
    }

    &.range {
        background-color: $primary !important;
    }

    &.range.faded {
        // background-color: lighten($gray-300, 5%) !important;
        // color: $gray-700 !important;

        background-color: rgba($primary, 0.25) !important;
        color: $primary !important;
    }
}

