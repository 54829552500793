// Side bar

body[data-sidebar=dark] #sidebar-menu ul li a i{
    color: white;
    background: #3f5084;
    border-radius: 3px;
    text-align: center;
    vertical-align: middle;
    margin-right: 5px;
    padding-top: 2px;
}


// dropdown
.dropdown-toggle {
    &:after {
        display: none;
    }
}

.transPending{
    width: calc(100% - 20px);
    background-color: $yellow-100;
    padding: 10px;
    border-radius: 2px;

    margin-right: 10px;
    margin-left: 10px;

    h6{
        margin-bottom: 0;
    }
}

.table-tag{
    margin-right: 10px;
    padding: 8px;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 10px;
}

.editable-text{
    font-size: 14px;
    text-decoration-line: underline;
    text-decoration-style: dotted;
    padding-top: 8px !important;
    position: absolute;
}

.editable-text-rel{
    font-size: 14px;
    text-decoration-line: underline;
    text-decoration-style: dotted;
    padding-top: 8px !important;
}

.not-editable-text{
    font-size: 14px;
    padding-top: 8px !important;
    position: absolute;
}

.not-editable-text-error{
    font-size: 14px;
    padding-top: 8px !important;
    position: absolute;
    color: #8c4a4a;
}

.editable-text span.badge{
    margin-right: 15px;
    margin-bottom: 15px;
}

hr.editable-text {
    border-top: 2px dotted black !important;
    margin: 0;
    opacity: 1;
    background: none !important;
}

.col-form-label{
    font-size: 14px !important;
}

.col-form-label-top, label.mb-0{
    font-size: 14px !important;
}

.tooltip {
    font-size: 14px;
}

.chip{
    display: inline-flex;
    flex-direction: row;
    background-color: #e5e5e5;
    border: none;
    cursor: default;
    height: 36px;
    outline: none;
    padding: 0;
    font-size: 14px;
    font-color: #333333;
    font-family:"Open Sans", sans-serif;
    white-space: nowrap;
    align-items: center;
    border-radius: 16px;
    vertical-align: middle;
    text-decoration: none;
    justify-content: center;
    margin-top: 5px;
    margin-right: 10px;
}

.item-header{
    font-size: 16px;
    font-weight: 500;
}

.chip-no-bg{
    border: 1px solid #d1dbf2 !important;
    background: none;
}

.chip-head-bg-none .chip-head{
    background: none !important;
}

.chip-head-bg-none .chip-head i{
    color: #a0a6b4;
    font-size: 25px;
}

.chip-head-purple .chip-head{
    background-color: #f1f5ff !important;
    color: #2a3042;
}

.chip-head-purple .chip-head i{
    color: #555862;
    font-size: 25px;
}

.chip-small{
    font-size: 12px;
    height: 25px;
}

.chip-small .chip-head{
    height: 22px !important;
}

.chip-head{
    display: flex;
    position: relative;
    overflow: hidden;
    background-color: #32C5D2;
    flex-shrink: 0;
    align-items: center;
    user-select: none;
    border-radius: 16px 0 0 16px;
    justify-content: center;
    color: #fff;
    height: 33px;
    margin-right: -4px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 0.5px;
}
.chip-content{
    cursor: inherit;
    display: flex;
    align-items: center;
    user-select: none;
    white-space: nowrap;
    padding-left: 12px;
    padding-right: 12px;
}
.chip-svg{
    color: #999999;
    cursor: pointer;
    height: auto;
    margin: 4px 4px 0 -8px;
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 24px;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;
    flex-shrink: 0;
}
.chip-svg:hover{
    color: #666666;
}
