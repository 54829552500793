/*
Template Name: Skote - Admin & Dashboard Template
Author: Themesbrand
Version: 3.0.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
//@import "variables-dark";
@import "./node_modules/bootstrap/scss/mixins.scss";
@import "./node_modules/bootstrap/scss/bootstrap";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";
@import "custom/components/alerts";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/dragula";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";

@import "custom/plugins/switch";
@import "custom/plugins/colorpicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/datatable";
@import "custom/plugins/responsive-table";
@import "custom/plugins/apexcharts";
@import "custom/plugins/chartist";

@import "custom/plugins/echarts";
@import "custom/plugins/google-map";
@import "custom/plugins/leaflet-maps";
@import "custom/plugins/owl-carousel";
@import "custom/plugins/lightbox";

// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
@import "custom/pages/email";
@import "custom/pages/file-manager";
@import "custom/pages/chat";
@import "custom/pages/projects";
@import "custom/pages/contacts";
@import "custom/pages/crypto";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";

//@import  "tabulator-tables/src/scss/themes/bootstrap/tabulator_bootstrap5.scss";
@import  "tabulator-tables/dist/css/tabulator_bootstrap5.css";

.tabulator-row.tabulator-group {
    box-sizing: border-box;
    border-bottom: 1px solid #c8ccdb;
    border-top: 1px solid #c8ccdb;
    background: #f6f8ff;
    padding: 14px 5px 14px 14px;
    font-weight: 600;
    min-width: 100%;
    color: #000000;
}

.tabulator-row.tabulator-group:hover {
    background: #eef0fa;
}

.tabulator-row.tabulator-selected {
    background-color: #e0fde6;
}

.tabulator-row.tabulator-selected:hover {
    background-color: #f8f8f8 !important;
}

.tabulator-row.tabulator-selectable:hover {
    background-color: #f8f8f8 !important;
}

.tabulator-row.tabulator-row-even {
    background-color: #f8f9fa;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    background: none !important;
}

pre {
    font-weight: 400;

    .number-line {
        color: #adadaf;
    }
    .string {
        color: #95c602;
    }
    .number {
        color: #f2b619;
    }
    .boolean {
        color: #0097f1;
    }
    .null {
        color: #727990;
    }
    .key {
        color: #fff;
    }
}

@media (min-width: 1700px) {
    .d-xxxl-block {
        display: block !important;
    }
}

@import "./custom";

// rtl
// @import "custom/rtl/general-rtl";
// @import "custom/rtl/bootstrap-rtl";
// @import "custom/rtl/spacing-rtl";
// @import "custom/rtl/float-rtl";
// @import "custom/rtl/text-rtl";
// @import "custom/rtl/structure-rtl";
// @import "custom/rtl/plugins-rtl";
// @import "custom/rtl/components-rtl";
// @import "custom/rtl/pages-rtl";
