//
// Owl carousel
//

@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

.navs-carousel {
    .owl-nav {
        margin-top: 16px;

        .owl-next, .owl-prev {
            width: 30px;
            height: 30px;
            line-height: 24px !important;
            font-size: 20px !important;
            border-radius: 50% !important;
            background-color: rgba($primary, 0.25) !important;
            color: $primary !important;
            margin: 4px 8px !important;
            padding: 4px 5px !important;
        }
    }
}
