/*
Template Name: Skote - Admin & Dashboard Template
Author: Themesbrand
Version: 3.0.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Custom Bootstrap Css File
*/

@use '@angular/material' as mat;

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
//@include mat.all-legacy-component-typographies();
//@include mat.legacy-core();

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/bootstrap";

@import "custom/components/reboot";
@import "custom/components/backgrounds";
@import "custom/components/badge";
@import "custom/components/buttons";
@import "custom/components/breadcrumb";
@import "custom/components/card";
@import "custom/components/dropdown";
@import "custom/components/nav";
@import "custom/components/table";
@import "custom/components/pagination";
@import "custom/components/progress";
