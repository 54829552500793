//
// Form-Upload
//

@import '~dropzone/dist/min/dropzone.min.css';
/* Dropzone */
.dropzone {
    min-height: 230px;
    border: 2px dashed $gray-400 !important;
    background: $card-bg !important;
    border-radius: 6px;

    .dropzone {
        border: none !important;
    }

    .dz-message {
        font-size: 24px;
        min-height: 150px !important;
        border: none !important;
        background: $card-bg !important;
        color: $input-color !important;
    }
}
