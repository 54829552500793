//
// _badge.scss
//


.badge {
    &[href] {
        &:hover,
        &:focus {
            color: $white;
        }
    }
}

// Soft badge
@mixin badge-variant-soft($bg) {
    color: $bg;
    background-color: rgba($bg, 0.18);

    &[href] {
        &:hover,
        &:focus {
            color: $bg;
            text-decoration: none;
            background-color: rgba($bg, 0.4);
        }
    }
}

@each $color, $value in $theme-colors {
    .badge {
        &.bg-#{$color} {
            &[href] {
                &:hover,
                &:focus {
                    background-color: darken($value, 4%) !important;
                }
            }
        }
    }

    .badge {
        &.bg-light {
            color: $body-color;

            &[href] {
                &:hover,
                &:focus {
                    color: $body-color;
                }
            }
        }
    }

    .badge-soft-#{$color} {
        @include badge-variant-soft($value);
    }

}

.rounded-pill {
    padding-right: .6em;
    padding-left: .6em;
}

// Dark badge

.badge.bg-dark {
    color: $light;
}


.badge.none {
    color: #f1506e;
    background-color: rgba(241, 80, 85, 0.18);
}

.badge.read {
    color: #44a5ce;
    background-color: rgba(80, 185, 241, 0.18);
}

.badge.read-write {
    color: #51b03b;
    background-color: rgba(110, 241, 80, 0.18);
}
